<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <drag-people :data="params"></drag-people>
    </base-card>
  </v-container>
</template>
<script>
// import DragPeopleCourse from '../../components/forms/DragPeopleCourse.vue'
export default {
  // components: { DragPeopleCourse },
  data: () => ({
    page: {
      title: 'Administrar membros'
    },
    params: {
      rolesParamsSearch: '',
      roles: '',
      title: '',
      linkGetPeopleAdm: '',
      linkSaveAdm: ''
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ],
    school: {},
    schoolFetch: false,
    course: {},
    courseFetch: false
  }),
  created () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    const rolesParams = this.$route.params.roles
    if (rolesParams === 'teachers') {
      this.page.title = 'Adicionar professores à turma'
      this.params = {
        roles: 'TEACHER',
        title: 'Professores',
        titleAdm: 'Professores na Turma',
        linkGetPeopleAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people?roles[]=TEACHER&per_page=200`,
        linkSaveAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people/sync`
      }
    } else if (rolesParams === 'students') {
      this.page.title = 'Adicionar alunos à turma'
      this.params = {
        roles: 'STUDENT',
        title: 'Alunos',
        titleAdm: 'Alunos na Turma',
        linkGetPeopleAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people?roles[]=STUDENT&per_page=200`,
        linkSaveAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people/sync`
      }
    }
  },

  methods: {
    async getCourseData () {
      this.courseFetch = false
      await this.$axios
        .get(`/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}`)
        .then(response => {
          this.course = response.data
          this.courseFetch = true
        })
    },
    async getSchoolData () {
      this.schoolFetch = false
      try {
        const classResponse = await this.$axios.get(`organizations/${this.id}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.schoolFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    }
  },

  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.getSchoolData()
    await this.getCourseData()

    this.breadcrumbs.push(
      {
        text: this.school.data.name,
        disabled: false,
        to: `/school/${this.$route.params.id}`
      },
      {
        text: 'Turmas',
        to: `/school/course/${this.$route.params.id}`
      },
      {
        text: this.course.data.name,
        to: `/course/${this.$route.params.id}/${this.$route.params.course}`
      },
      {
        text: this.page.title,
        disabled: true
      }
    )
  }
}
</script>
